import Image from "next/image";

export default function Footer({ company }) {
    return (
        <>
            <footer className="footer-section">
                <div className="footer-wrapper pt-120 position-relative z-1 overflow-hidden">
                    <Image
                        src={'/img/shapes/texture-bg.png'}
                        alt="rodape"
                        style={{ zIndex: -1 }}
                        fill />
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xl-4 col-lg-4">
                                <div className="footer-widget widget-basic mb-40">
                                    <h3 className="widget-title-large mb-4 text-white">Tem alguma dúvida? Fale conosco e te ajudamos...</h3>
                                    <p className="mb-40">Nossos vendedores possuem anos de experiência para tirar todas suas dúvidas e te dar o melhor atendimento.</p>
                                    <div className="phone-box d-flex align-items-center">
                                        <span className="icon-wrapper d-inline-flex align-items-center justify-content-center rounded-circle bg-primary text-white"><i className="flaticon-phone-call"></i></span>
                                        <div>
                                            <a href={`tel:+55${company.phone}`}>
                                                <h4 className="text-white ms-3 mb-0">
                                                    {
                                                        company.phone.length === 10
                                                            ? `(${company.phone.substring(0, 2)}) ${company.phone.substring(2, 6)}-${company.phone.substring(6, 10)}`
                                                            : company.phone.length === 11
                                                                ? `(${company.phone.substring(0, 2)}) ${company.phone.substring(2, 7)}-${company.phone.substring(7, 11)}`
                                                                : company.phone
                                                    }<li>(11) 4103-2621</li>
                                                </h4>
                                                <h4 className="widget-title-large ms-3 mb-0 text-white"><a href="https://api.whatsapp.com/send?phone=5511950395102" target='_blank' rel="noreferrer" style={{ color: 'white' }}>(11) 95039-5102 </a><div className="fab fa-whatsapp"></div></h4>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5">
                                <div className="footer-widget widget-basic">
                                    <div className="text-white h6">Endereço </div>
                                    <p className="mb-40">Rua Afonsina , Rudge Ramos, São Bernardo do Campo - SP< br />CEP: 09633-000</p>
                                    <div className="text-white h6">Horários de Funcionamento</div>
                                    <p className="mb-40">Segunda à Sexta : 9:00 às 18:00< br />Sábados e feriados : 9:00 às 14:00</p>
                                    <p>CNPJ: 07.549.815/0001-73</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3">
                                <div className="ms-lg-5 ms-xl-0 mt-5 mt-lg-0">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="widget-title text-white mb-3 h6">Links Rápidos</div>
                                            <ul className="footer-nav">
                                                {/* <li><a href="/novos">0 km</a></li> */}
                                                <li><a href="/seminovos">Estoque</a></li>
                                                {/* <li><a href="/blindados">Blindados</a></li> */}
                                                <li><a href="/financiamento">Financiamento</a></li>
                                                <li><a href="/venda-seu-veiculo">Venda Seu Veículo</a></li>
                                                {/* <li><a href="/seguro">Seguro</a></li> */}
                                                <li><a href="/sobre-nos">Sobre</a></li>
                                                <li><a href="/contato">Contato</a></li>
                                            </ul>
                                        </div>
                                        <div className="footer-social d-inline-block text-start">
                                            < br />
                                            <div className="text-white h6">Siga-nos</div>
                                            <ul className="footer-social-list">
                                                {company.facebook ? <li><a href={company.facebook} target={'_blank'} rel="noreferrer" aria-label="Acesse o facebook da nossa loja"><i className="fab fa-facebook-f"></i></a></li> : null}
                                                {company.instagram ? <li><a href={company.instagram} target={'_blank'} rel="noreferrer" aria-label="Acesse o instagram da nossa loja"><i className="fab fa-instagram"></i></a></li> : null}
                                                {company.youtube ? <li><a href={company.youtube} target={'_blank'} rel="noreferrer" aria-label="Acesse o youtube da nossa loja"><i className="fab fa-youtube"></i></a></li> : null}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-sm-7">
                                    <div className="copyright-text">
                                        <p className="mb-0">&copy; Todos os direitos reservados. Desenvolvido por &nbsp;&nbsp;<a href="https://bndv.com.br" target="_blank" rel="noreferrer">BNDV - Banco Nacional de Veículos</a></p>
                                    </div>
                                </div>
                                <div className="col-sm-5">
                                    <div className="copyright-links text-start text-sm-end mt-1 mt-sm-0">
                                        <a href="/termos">Termo de Uso e Política de Privacidade</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}